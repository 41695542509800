.DefaultLoader {
  padding: 60px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .Loader {
    position: relative;

    svg {
      height: 60px;
      width: auto;

      .elem1 {
        animation: animate-svg-stroke-1 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) 0s both,
          animate-svg-fill-1 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.5s both;
      }

      .elem2 {
        animation: animate-svg-stroke-2 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.12s
            both,
          animate-svg-fill-2 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.75s both;
      }

      .elem3 {
        animation: animate-svg-stroke-3 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) 0.24s
            both,
          animate-svg-fill-3 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 1s both;
      }
    }
  }
}

@keyframes animate-svg-stroke-1 {
  0% {
    stroke-dashoffset: 1107.107177734375px;
    stroke-dasharray: 1107.107177734375px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 1107.107177734375px;
  }
}

@keyframes animate-svg-fill-1 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 123, 232);
  }
}

@keyframes animate-svg-stroke-2 {
  0% {
    stroke-dashoffset: 1423.426025390625px;
    stroke-dasharray: 1423.426025390625px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 1423.426025390625px;
  }
}

@keyframes animate-svg-fill-2 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 123, 232);
  }
}

@keyframes animate-svg-stroke-3 {
  0% {
    stroke-dashoffset: 146.51326206513048px;
    stroke-dasharray: 146.51326206513048px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 146.51326206513048px;
  }
}

@keyframes animate-svg-fill-3 {
  0% {
    fill: transparent;
  }

  100% {
    fill: rgb(0, 123, 232);
  }
}
