.tradeScreenshots {
  .screenshot {
    cursor: pointer;
    user-select: none;
    margin-bottom: 30px;

    img {
      width: 100%;
      height: 240px;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 570px) {
  .tradeScreenshots {
    .screenshot {
      img {
        height: 120px;
      }
    }
  }
}
