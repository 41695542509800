@import '../../mixins';

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-color: $text-muted-dark;
}

.ant-tabs-tab .ant-tabs-tab-btn,
.ant-tabs-tab:hover {
  color: $text-muted-dark;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab:hover {
  color: $primary;
}
