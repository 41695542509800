@import './../mixins';

.simple-pagination {
  margin: 30px 0;

  .simple-pagination__wrapper {
    user-select: none;

    .pagination-item {
      span {
        border: 1px solid $gray-2;
        position: relative;
        display: block;
        padding: 0.5rem 0.75rem;
        margin-left: -1px;
        font-size: 0.9rem;
        line-height: 1.25;
        color: $primary;
        background-color: #fff;
        transition: all 0.3s ease;

        &:hover {
          background: $primary;
          color: $white;
          cursor: pointer;
          transition: all 0.3s ease;
        }
      }
    }

    .pagination-text,
    .pagination-item__disabled {
      span {
        color: $black !important;

        &:hover {
          background: transparent;
          cursor: default;
        }
      }
    }

    .pagination-item__disabled span {
      color: $gray-2 !important;
    }
  }
}
