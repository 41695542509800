@import '../mixins';

.list-group {
  .list-group-item {
    color: $text-muted-dark;
    background: $dark-card-background;

    .text-muted {
      color: $gray-5 !important;
    }

    .list-item-value {
      color: $text-muted-dark;
    }

    .ant-progress-text {
      color: $text-muted-dark;
    }

    .list-icon {
      .avatar {
        color: $primary;
        background-color: rgba(90, 141, 238, 0.4) !important;
      }
    }

    &:hover {
      background-color: darken($dark-card-background, 5%);

      .ant-progress-inner {
        background-color: $white;
      }
    }
  }

  &__stats {
    .list-group-item {
      border-bottom: 1px solid $gray-0 !important;
    }
  }
}
