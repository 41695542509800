@import '../mixins';

.ant-progress-inner {
  background-color: $gray-1;
  overflow: visible;
}

.ant-progress-success-bg,
.ant-progress-bg {
  background-color: $primary;
  box-shadow: 0 2px 6px 0 rgba(90, 141, 238, 0.4);
}

.win-loss-progress {
  .ant-progress-inner {
    background-color: $danger;
    box-shadow: 0 2px 6px 0 rgba(234, 44, 84, 0.15);
  }

  .ant-progress-success-bg,
  .ant-progress-bg {
    background-color: $success;
    box-shadow: 0 2px 6px 0 rgba(40, 167, 69, 0.15);
  }
}
