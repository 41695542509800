.tradeHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .forexLogo {
    position: relative;
    height: 168px;
    width: 168px;
    flex: 0 0 168px;
    margin-right: 36px;

    img {
      height: 114px;
      width: 114px;
      border-radius: 50%;
      position: absolute;
      right: 0;
      top: 0;
      font-size: 0;
    }

    img:first-child {
      transform: translate(-54px, 54px);
    }

    img:last-child {
      clip-path: path('M113 0H0V48c35-1 66 26 66 66v0h48V0Z');
    }
  }

  .tradeInfo {
    h2 {
      margin-top: 0;
      margin-bottom: 20px;
      color: var(--tr-primary-text-color);
      font-size: 48px;
      line-height: 64px;
      font-weight: 600;
    }

    .shortDescription {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 24px;
      color: var(--tr-primary-text-color);
      font-weight: 500;

      small {
        color: var(--tr-muted-text-color);
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }
    }

    .tradeControls {
    }
  }
}

@media screen and (max-width: 1200px) {
  .tradeHeader {
    .forexLogo {
      height: 56px;
      width: 56px;
      flex: 0 0 56px;
      margin-right: 18px;

      img {
        width: 36px;
        height: 36px;
      }

      img:first-child {
        transform: translate(-18px, 18px);
      }

      img:last-child {
        clip-path: path('M38 0H0v16.02L1 16a21 21 0 0 1 20.98 22H38V0Z');
      }
    }

    .tradeInfo {
      h2 {
        margin-bottom: 10px;
        font-size: 28px;
        line-height: 36px;
      }

      .shortDescription {
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 24px;

        small {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 570px) {
  .tradeHeader {
    .forexLogo {
      display: none;
    }

    .tradeInfo {
      h2 {
        margin-bottom: 10px;
        font-size: 20px;
        line-height: 26px;
      }

      .shortDescription {
        font-size: 16px;
        line-height: 20px;

        small {
          color: var(--tr-muted-text-color);
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}
