.newsItem {
  margin-bottom: 24px;
  border: 1px solid var(--tr-border-color-gray-1);
  border-radius: 12px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;

  .image {
    flex: 0 0 360px;
    height: 200px;
    margin-right: 24px;

    a {
      display: block;
      width: 360px;
      height: 200px;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  .content {
    padding: 24px 24px 24px 0;
    flex: 0 0 calc(100% - 24px - 360px);

    h2 {
      margin-top: 0;
      margin-bottom: 12px;
      font-size: 24px;
      line-height: 36px;
      font-weight: 500;
      cursor: pointer;

      a {
        cursor: pointer;
        color: var(--tr-primary-text-color);
        transition: all 0.3s ease-in-out;

        &:hover {
          color: var(--tr-primary);
          transition: all 0.3s ease-in-out;
        }
      }
    }

    p {
      margin-bottom: 0;
      font-size: 16px;
      line-height: 20px;
      color: var(--tr-muted-text-color);
      font-weight: 400;
    }

    .info {
      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        li {
          margin-top: 24px;
          font-size: 14px;
          line-height: 18px;
          color: var(--tr-muted-text-color);
          font-weight: 400;
          margin-right: 12px;
          cursor: default;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .newsItem {
    flex-direction: column;

    .image {
      display: none;
    }

    .content {
      padding: 16px;
      flex: 0 0 100%;

      h2 {
        font-size: 20px;
        line-height: 26px;
      }

      p {
        font-size: 14px;
        line-height: 18px;
      }

      .info {
        ul {
          li {
            margin-top: 12px;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }
}
