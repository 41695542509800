@import '../mixins';

.advanced-stats {
  .as-menu__wrapper {
    .as-menu__item {
      .item__name {
        color: $text-muted-dark;
      }
    }

    .active,
    .as-menu__item:hover {
      background: $dark-card-background;
    }
  }

  .advanced-stat__values {
    .advanced-stat__values-list {
      .advanced-stat__values-item {
        border-bottom: 1px solid $text-muted-dark;
      }
    }
  }
}
