@import '../mixins';

.card {
  box-shadow: $default-card-shadow;
  border-color: $gray-2;
  border-width: 0;
  background: $white;
}

.card__stat {
  background: $white;

  .card-header {
    background: transparent;
    border-bottom: 0;
    user-select: none;
    padding: 1.4rem 1.7rem;

    .card-title {
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 500;
      letter-spacing: 0.02rem;
      font-size: 1rem;
      color: $gray-6;
    }
  }

  .card-header__fix-pl {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .card-content {
    padding: 0 1.7rem 1.4rem;

    .card-body {
      padding: 0;

      .card-content__stats {
        .card-content__stat {
          span {
            font-size: 0.9rem;
            color: $gray-6;
            font-weight: 400;
          }

          h3 {
            color: $black;
            font-weight: 700;
            font-size: 1.56rem;
          }
        }
      }
    }
  }

  .card-content__fixed-paddings {
    padding: 0 1.25rem 1.25rem;
  }

  .card-content__no-title {
    padding-top: 1.7rem;
  }
}

.card__premium-unlock {
  border-color: $warning;

  .card-content {
    background: $warning;

    .card-body {
      h3 {
        margin-top: 0;
        margin-bottom: 10px;
        font-size: 20px;
        line-height: 1.4;
        color: #fff;
      }

      p {
        color: #fff;
        font-size: 14px;
        line-height: 1.3;
        margin-bottom: 5px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .btn {
        margin-top: 10px;
      }
    }
  }
}

.card__premium-unlock__compact .card-content {
  padding: 30px 0;
}
