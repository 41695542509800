.ant-modal-mask {
  backdrop-filter: blur(4px);
  background: var(--modal-overlay);
}

.ant-modal {
  color: var(--tr-primary-text-color);
}

.ant-modal-content {
  background-color: var(--tr-sidebar-bg);
  border-radius: 6px;
  box-shadow: 0 8px 23px rgba(var(--tr-shadow-umbra-color), 0.28), 0 0 transparent,
    0 0 transparent;
}

.ant-modal-header {
  padding: 16px 24px;
  color: var(--tr-primary-text-color) !important;
  background: var(--tr-sidebar-bg) !important;
  border-bottom: 1px solid var(--tr-border-color-gray-1) !important;
  border-radius: 6px 6px 0 0;
}
