.totalPerformanceChart {
  position: relative;
  border-right: 1px solid var(--tr-border-color-gray-1);
  height: 400px;
  padding: 22px;
}

@media screen and (max-width: 780px) {
  .totalPerformanceChart {
    height: auto;
    border-right: 0;
  }
}

@media (min-width: 1200px) and (max-width: 1700px) {
  .totalPerformanceChart {
    height: auto;
  }
}
