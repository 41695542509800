.InstrumentReference {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: default;
  user-select: none;

  .Logo {
    position: relative;
    flex-shrink: 0;
    height: 24px;
    width: 24px;

    .Base,
    .Quote {
      position: absolute;
      right: 0;
      top: 0;
      width: 16px;
      height: 16px;

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .Base {
      top: 8px;
      right: 8px;
      z-index: 2;
    }

    .Quote {
      z-index: 1;
      clip-path: path('M16 8A8 8 0 0 0 .3 6a10 10 0 0 1 9.7 9.7A8 8 0 0 0 16 8Z');
    }
  }

  .Name {
    margin-left: 8px;
    font-weight: 600;
    color: var(--tr-primaryDark);
    padding: 4px 6px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 1;
    background: var(--tr-primaryLight);
  }
}
