.StocksSlider {
  position: relative;
  margin-top: 0;
  margin-bottom: 88px;

  .SliderItem {
    mix-blend-mode: normal;
    border-radius: 6px;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
    overflow: hidden;

    a {
      display: block;
      outline: none;
      text-decoration: none;
      user-select: none;
      padding: 20px;
      background: var(--tr-sidebar-bg);
      transition: all 0.3s ease-in-out;

      .Title {
        margin-top: 0;
        margin-bottom: 20px;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: var(--tr-muted-text-color);
      }

      .Logo {
        padding: 6px;
        width: 42px;
        height: 42px;
        background: var(--tr-border-color-gray-1);
        border-radius: 50%;
        overflow: hidden;
        margin-bottom: 11px;
        position: relative;

        &__Wrapper {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
        }
      }

      .InstrumentName {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-top: 0;
        margin-bottom: 2px;
        color: var(--tr-muted-text-color);

        span {
          color: var(--tr-primary-text-color);
          font-weight: 700;
          max-width: 100px;
          overflow: hidden;
        }
      }

      .Price {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: var(--tr-primary-text-color);
        margin-bottom: 2px;
      }

      .Value {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: var(--tr-success);

        svg {
          margin-top: -2px;
          margin-left: 8px;

          path {
            fill: var(--tr-success);
          }
        }
      }

      .Value[data-direction='down'] {
        color: var(--tr-danger);

        svg {
          transform: rotate(75deg);

          path {
            fill: var(--tr-danger);
          }
        }
      }
    }
  }
}

@media (min-width: 1500px) {
  .StocksSlider {
    display: block;
    max-width: 1440px;
    width: 100%;
    margin: 0 auto 88px;
  }
}
