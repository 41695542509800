@import '../mixins';

.muted-color {
  color: var(--tr-muted-text-color) !important;

  &:hover {
    color: var(--tr-muted-text-color) !important;
  }
}

// Backgrounds
.primary-background {
  background: var(--tr-primary) !important;
  transition: $default_transition;

  &:hover {
    background: var(--tr-primaryDark) !important;
    transition: $default_transition;
  }
}

.success-background {
  background: var(--tr-success) !important;
  transition: $default_transition;

  &:hover {
    background: var(--tr-successDark) !important;
    transition: $default_transition;
  }
}

.warning-background {
  background: var(--tr-warning) !important;
  transition: $default_transition;

  &:hover {
    background: var(--tr-warningDark) !important;
    transition: $default_transition;
  }
}

.danger-background {
  background: var(--tr-danger) !important;
  transition: $default_transition;

  &:hover {
    background: var(--tr-dangerDark) !important;
    transition: $default_transition;
  }
}

.primary-color {
  color: var(--tr-primary) !important;

  &:hover {
    color: var(--tr-primaryDark) !important;
  }
}

.success-color {
  color: var(--tr-success) !important;

  &:hover {
    color: var(--tr-successDark) !important;
  }
}

.warning-color {
  color: var(--tr-warning) !important;

  &:hover {
    color: var(--tr-warningDark) !important;
  }
}

.danger-color {
  color: var(--tr-danger) !important;

  &:hover {
    color: var(--tr-dangerDark) !important;
  }
}

.muted-color {
  color: var(--tr-muted-text-color) !important;

  &:hover {
    color: var(--tr-muted-text-color) !important;
  }
}
