@import './../mixins';

.ant-breadcrumb a,
.ant-breadcrumb-separator,
.ant-breadcrumb > span:last-child {
  color: var(--tr-muted-text-color);
}

span.ant-breadcrumb-link {
  color: var(--tr-muted-text-color);
  cursor: default;
}
