@import './src/scss/mixins';

.card__transaction {
  user-select: none;
  margin-bottom: 15px;

  .card_transaction__body {
    padding: 8px 24px;

    .transactions__arrow {
      font-size: 1.2rem;
    }

    .transactions__arrow_success {
      color: $success;
    }

    .transactions__arrow_danger {
      color: $danger;
    }

    .transactions__value {
      font-weight: 600;
      font-size: 1rem;
      color: $gray-7;
      width: 35%;
    }

    .transactions__date {
      font-weight: 400;
      font-size: 1rem;
      color: $gray-7;
    }

    .transactions__buttons {
      padding: 5px;
      background: $gray-2;
      border-radius: 50%;
      width: 36px;
      text-align: center;
      cursor: pointer;
      color: $white;
      font-size: 1.05rem;
    }
  }
}
