.tradeDetails {
  .details {
    .detailsItems {
      .item {
        margin-bottom: 16px;

        p {
          font-size: 14px;
          line-height: 20px;
          font-weight: 600;
          color: var(--tr-primary-text-color);
          margin-bottom: 0;

          svg {
            width: 20px;
            height: 20px;
            margin-top: -2px;
          }
        }

        h4 {
          margin-bottom: 0;
          margin-top: 0;
          font-weight: 400;
          color: var(--tr-primary-text-color);
          font-size: 20px;
          line-height: 30px;

          svg {
            width: 20px;
            height: 20px;
            margin-top: -2px;
          }
        }

        .asText {
          h2 {
            margin-top: 0;
            margin-bottom: 16px;
            font-size: 20px;
            line-height: 26px;
            color: var(--tr-primary-text-color);
            font-weight: 600;
          }

          h3 {
            margin-top: 0;
            margin-bottom: 14px;
            font-size: 18px;
            line-height: 22px;
            color: var(--tr-primary-text-color);
            font-weight: 600;
          }

          h4 {
            margin-top: 0;
            margin-bottom: 12px;
            font-size: 16px;
            line-height: 20px;
            color: var(--tr-primary-text-color);
            font-weight: 500;
          }

          p {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            color: var(--tr-primary-text-color);
            margin-bottom: 12px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
