@import './src/scss/mixins';

.tr_footer {
  user-select: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 !important;

  .footer__copyrights {
    font-size: 0.85rem;
    color: $text-muted;
    line-height: 1;
  }

  .footer__menu {
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      user-select: none;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      li {
        a {
          font-size: 0.85rem;
          color: $text-muted;
          line-height: 1;
          padding: 0 10px;

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }

  .footer__version {
    opacity: 0;
    font-size: 0.85rem;
    color: $gray-7;
    line-height: 1;
  }
}
