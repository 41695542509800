html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.match-height {
  > [class*='col'] {
    display: flex;
    flex-flow: column;

    > div {
      flex: 1 1 auto;
    }
  }
}
