@import './../mixins';

// Links
a {
  color: $primary;
  text-decoration: none !important;
  outline: none !important;
}

// Font Weights
.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-900 {
  font-weight: 900;
}

b,
strong {
  @extend .fw-600;
}

// Headings
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.7rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  color: $dark;

  small {
    padding-left: 0.5rem;
    color: $text-muted;
  }
}

h1,
.h1 {
  font-size: 2rem;
}

h2,
.h2 {
  font-size: 1.74rem;
}

h3,
.h3 {
  font-size: 1.51rem;
}

h4,
.h4 {
  font-size: 1.32rem;
}

h5,
.h5 {
  font-size: 1.14rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.small,
small {
  font-size: 0.857rem;
}

.type__wrapper {
  img {
    max-width: 100%;
    height: auto;
  }

  p,
  ul li,
  ol li,
  table tr td,
  th {
    font-size: 1rem;
    line-height: 1.2;
    color: $text;
    font-weight: 400;
  }

  p,
  ul li,
  ol li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  table tr td,
  table tr th {
    padding: 8px;
  }

  table tr th {
    font-weight: 600;
  }
}

.font-size__x2 {
  font-size: 200%;
}

.font-size__x1-5 {
  font-size: 150%;
}
