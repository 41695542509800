@import '../../../../scss/mixins';

.simulatorFilters {
  padding: 0;

  .filtersScrollbar {
    max-height: 600px;
    overflow: auto;
  }

  .filtersWrapper {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    .title {
      margin-top: 0;
      margin-bottom: 12px;
      font-weight: 700;
      font-size: 20px;
      line-height: 1;
    }
  }
}
