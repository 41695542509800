@import '../mixins';

.subscription-promo {
  .subscription-promo__details,
  .subscription-promo__subscribe-block {
    h2 {
      margin-top: 24px;
      margin-bottom: 12px;
      line-height: 1.25;
      font-size: 28px;
      color: $black;
      font-weight: 600;
      position: relative;
      z-index: 1;

      strong {
        position: relative;
        z-index: 10;

        &:before {
          content: '';
          width: 100%;
          height: 35%;
          background: $primary;
          position: absolute;
          left: 0;
          top: 55%;
          z-index: 2;
          opacity: 0.15;
        }
      }
    }

    .bt-1 {
      border-top: 1px solid $gray-2;
      margin-top: 24px;
      padding-top: 24px;
    }
  }

  .subscription-promo__details {
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;

      li {
        position: relative;
        padding-left: 30px;
        margin-bottom: 12px;
        font-size: 18px;
        font-weight: normal;
        line-height: 1.25;

        sup {
          font-size: 11px;
          line-height: 1;
          color: $warning;
          font-weight: 300;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 22px;
          height: 22px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24' stroke='%23007be8' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 22px;
        }
      }
    }
  }

  .subscription-promo__subscribe-block {
    background: #fff;
    border-radius: 10px;
    padding: 24px;
    border: 1px solid $gray-2;
    box-shadow: $shadow-4;

    h2 {
      margin-top: 0;
      margin-bottom: 20px;
    }

    p {
      line-height: 1.25;
      font-size: 16px;
      color: $black;
      margin-bottom: 24px;
      font-weight: 300;
    }

    table {
      width: 100%;

      tr {
        border-bottom: 1px solid $gray-2;

        td {
          padding: 18px 12px;
          font-size: 16px;
          line-height: 1.25;
          font-weight: 400;

          &:nth-child(2) {
            font-size: 20px;
          }

          small {
            font-size: 12px;
            opacity: 0.8;
          }
        }

        &:first-child td {
          padding-top: 0;
        }

        &:last-child {
          border-bottom: 0;
        }
      }

      .total td {
        font-weight: 600;
        font-size: 28px;
        padding-bottom: 0;

        &:last-child {
          font-size: 32px;
        }
      }
    }
  }
}
