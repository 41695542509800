.ant-alert-error {
  background-color: rgba(220, 53, 69, 0.1);
  border: 1px solid $danger;

  .ant-alert-message {
    color: $white;
  }

  .ant-alert-icon {
    color: $danger;
  }
}
