@import '../mixins';

.jira-card {
  background-color: $white;
  border-radius: 3px;
  box-shadow: $jira-card;
  margin-bottom: 1rem;
  padding: 0.33rem;
  min-height: 2.66rem;
  cursor: default;

  &__title {
    color: $black;
    font-weight: 600;
    line-height: 1.1;
    margin-bottom: 5px;
  }
}
