.headerShortcutsWrapper {
  background: var(--tr-sidebar-bg);
  width: 100%;
  min-width: 300px;
  max-width: 300px;
  box-shadow: 0 4px 11px rgba(var(--tr-shadow-umbra-color), 0.16), 0 0 transparent,
    0 0 transparent;
  border-radius: 6px;
  overflow: hidden;

  &_Header {
    padding: 16px 24px;

    .title {
      margin: 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: var(--tr-primary-text-color);
    }
  }

  &_Blocks {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    &_Block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 12px;
      width: 150px;
      height: 120px;
      border-top: 1px solid var(--tr-border-color-gray-1);

      &:nth-child(2n + 1) {
        border-right: 1px solid var(--tr-border-color-gray-1);
      }

      .Icon {
        .IconWrapper {
          svg {
            path {
              fill: var(--tr-muted-text-color);
              transition: all 0.3s ease-in-out;
            }
          }
        }
      }

      .Title {
        margin-top: 8px;

        h4 {
          margin: 0;
          font-size: 15px;
          line-height: 21px;
          font-weight: 500;
          color: var(--tr-muted-text-color);
          transition: all 0.3s ease-in-out;
        }
      }

      &:hover {
        background: var(--tr-layout-bg);

        .Icon {
          .IconWrapper {
            svg {
              path {
                fill: var(--tr-primary);
                transition: all 0.3s ease-in-out;
              }
            }
          }
        }

        .Title {
          h4 {
            color: var(--tr-primary);
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
  }
}
