.layout {
  .firstRow,
  .secondRow {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 24px;
    gap: 24px;
    margin-bottom: 24px;
  }

  .secondRow {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 570px) {
  .layout .firstRow {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 571px) and (max-width: 1200px) {
  .layout .firstRow {
    grid-template-columns: repeat(2, 1fr);
  }
}
