@import './../mixins';

.avatar {
  background-color: $gray-1;
  position: relative;
  cursor: pointer;
  display: inline-flex;
  font-size: 0.8rem;
  text-align: center;
  margin: 5px;
  color: $white;
  border-radius: 50%;
  vertical-align: middle;
  white-space: nowrap;

  .avatar-content {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
