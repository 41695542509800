.fullWidthSubscribeWidget {
  padding: 8px 22px;
  background: linear-gradient(
    112deg,
    #00d2ff 0%,
    var(--tr-primary) 50%,
    var(--tr-primaryDark) 100%
  );
  color: #fff;

  h3 {
    margin: 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 1;
    color: #fff;
  }
}
