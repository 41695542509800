@import '../mixins';

.ql-toolbar.ql-snow {
  border: 1px solid $gray-2;
  font-family: $base-font-family;
  border-top-left-radius: $form-border-radius;
  border-top-right-radius: $form-border-radius;
}

.ql-container.ql-snow {
  border: 1px solid $gray-2;
  border-bottom-left-radius: $form-border-radius;
  border-bottom-right-radius: $form-border-radius;
}

.ql-editor {
  min-height: 500px;

  img {
    max-width: 100%;
    height: auto;
  }

  p,
  ul li,
  ol li,
  table tr td,
  th {
    font-size: 0.95rem;
    line-height: 1.3;
    font-weight: 400;
  }

  p,
  ul li,
  ol li {
    margin-bottom: 10px;
  }

  table tr td,
  table tr th {
    padding: 8px;
  }

  table tr th {
    font-weight: 600;
  }
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: $primary;
}

.trade-notes__form {
  .ql-editor {
    min-height: 150px;
    border: 1px solid $gray-2;
    border-radius: 6px;
  }

  .ql-bubble .ql-picker-options,
  .ql-bubble .ql-tooltip {
    background-color: $gray-6;
  }

  .ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow {
    border-bottom: 6px solid $gray-6;
  }

  .ql-bubble .ql-tooltip {
    z-index: 999999 !important;
  }

  .ql-bubble .ql-picker {
    color: $white;
  }

  .ql-bubble .ql-stroke {
    stroke: $white;
  }
}

.trade-note-editor {
  .ql-editor {
    min-height: 120px;
  }
}
