@import '../mixins';

.ql-toolbar.ql-snow {
  border: 1px solid $dark-form-border;
}

.ql-container.ql-snow {
  border: 1px solid $dark-form-border;
}

.trade-notes__form {
  .ql-editor {
    border: 1px solid $dark-form-border;
  }

  .ql-bubble .ql-picker-options,
  .ql-bubble .ql-tooltip {
    background-color: $dark-form-border;
  }

  .ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow {
    border-bottom: 6px solid $dark-form-border;
  }

  .ql-bubble .ql-picker {
    color: $text-muted-dark;
  }

  .ql-bubble .ql-stroke {
    stroke: $text-muted-dark;
  }
}

.ql-toolbar.ql-snow .ql-picker-options {
  background-color: $dark-form-border;
  border-color: $dark-form-border;
  color: $text-muted-dark;
}

.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border-color: $dark-form-border;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label,
.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  color: $text-muted-dark;
}

.ql-snow .ql-stroke {
  stroke: $text-muted-dark;
}

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: $text-muted-dark;
}
