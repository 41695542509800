@import './src/scss/mixins';

.error_title {
  font-size: 2rem;
  line-height: 1.3;
  font-weight: 700;
  color: $black;
}

.error_title_x2 {
  font-size: 3rem;
}

.error_message {
  color: $gray-5;
  font-size: 1.2rem;
  line-height: 1.1;
  margin-bottom: 1rem;
}
