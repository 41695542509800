.tr-table {
  thead {
    user-select: none;

    tr {
      th {
        padding-top: 0;
        border-top: 0;
      }

      .sortable {
        cursor: pointer;

        &:hover {
          color: var(--tr-primary);
        }
      }

      .sortable.active {
        color: var(--tr-primary);

        i {
          margin-left: 10px;
        }
      }
    }
  }

  tbody {
    .tr-table__clickable-row {
      user-select: none;
      cursor: pointer;
    }

    tr td {
      vertical-align: middle;
    }
  }
}

.table__fixed-cols-3 {
  tbody {
    td {
      width: 33%;
    }
  }
}

.table__xs {
  tr {
    td,
    th {
      padding: 0.55rem;
    }
  }
}

.white-space-nowrap {
  white-space: nowrap;
}

.table__v2 {
  tr {
    td {
      border-top: 1px solid var(--tr-border-color-gray-1);
    }
  }
}
