.widgetWithProgress {
  position: relative;

  .wrapper {
    height: 300px;

    .item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 14px;

      &:last-child {
        margin-bottom: 0;
      }

      .iconWrapper {
        margin-right: 14px;

        .icon {
          position: relative;
          width: 42px;
          height: 42px;
          border-radius: 6px;
          background-color: var(--tr-primaryLight);

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: var(--tr-primary);
            font-weight: 500;
            font-size: 18px;
            line-height: 1;
          }
        }
      }

      .name {
        letter-spacing: 0.1px;
        margin-right: 8px;

        h4 {
          margin-top: 0;
          margin-bottom: 0;
          font-size: 14px;
          line-height: 18px;
          font-weight: 500;
          color: var(--tr-primary-text-color);
        }

        p {
          font-size: 12px;
          line-height: 13px;
          color: var(--tr-muted-text-color);
          font-weight: 400;
          margin-bottom: 0;

          sup {
            font-size: 10px;
            line-height: 1;
            font-weight: 400;
            margin-left: 4px;
            color: var(--tr-success);
          }

          sup.danger {
            color: var(--tr-danger);
          }
        }
      }

      .info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: auto;

        .percents {
          text-align: center;

          h5 {
            margin: 0;
            color: var(--success);
            font-weight: 600;
            font-size: 14px;
            line-height: 1;
          }

          p {
            margin-bottom: 0;

            small {
              font-size: 12px;
              line-height: 14px;
              color: var(--tr-muted-text-color);
              font-weight: 400;
              margin-bottom: 0;
            }
          }
        }

        .percentsWarning h5 {
          color: var(--tr-warning);
        }

        .percentsDanger h5 {
          color: var(--tr-danger);
        }

        .progress {
          margin-left: 14px;
        }
      }
    }
  }
}
