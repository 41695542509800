.ant-alert {
  border-radius: $form-border-radius;
}

.ant-alert-warning {
  background-color: var(--tr-warningLight);
  border: 1px solid var(--tr-warning);

  .ant-alert-message {
    color: $text;

    a {
      font-weight: 500;
    }
  }

  .ant-alert-icon {
    color: var(--tr-warning);
  }
}

.ant-alert-error {
  background-color: rgba(220, 53, 69, 0.1);
  border: 1px solid $danger;

  .ant-alert-message {
    color: $text;

    a {
      font-weight: 500;
    }
  }

  .ant-alert-icon {
    color: $danger;
  }
}
