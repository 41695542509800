.ant-drawer-content,
.ant-drawer-header {
  background-color: $dark-card-background;
}

.ant-drawer-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  .ant-drawer-title {
    color: #fff;
  }
}

.ant-drawer-close {
  color: #fff;
}

.ant-drawer-footer {
  border-color: rgba(255, 255, 255, 0.1);
}

.single-trade-drawer {
  .ant-drawer-content,
  .ant-drawer-header {
    background-color: $dark-body-background;
  }

  .single-trade__card {
    background: $dark-card-background;
    border: 1px solid $dark-form-border;
    box-shadow: $default-card-shadow__dark;
  }
}
