@import '../mixins';

.single-trade-drawer {
  .ant-drawer-content-wrapper {
    width: calc(100% - 260px) !important;
  }
}

@media (min-width: 1200px) {
  .single-trade-drawer {
    .ant-drawer-mask {
      backdrop-filter: blur(6px);
    }

    .ant-drawer-header {
      border-bottom: 0;
      padding: 0;

      .ant-drawer-close {
        position: absolute;
        top: 12px;
        left: -46px;
        width: 34px;
        height: 34px;
        background: var(--tr-sidebar-bg);
        border-radius: 50%;
        box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
      }
    }
  }
}

@media screen and (max-width: 1140px) {
  .single-trade-drawer {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
}

.single-trade__card {
  border: 0 solid #e4e8f1;
  border-radius: 0.428rem;
  margin-bottom: 2rem;
  box-shadow: $default-card-shadow;
  transition: all 0.3s ease-in-out, background 0s, color 0s, border-color 0s;

  &:last-child {
    margin-bottom: 0;
  }

  .single-trade__card-header {
    padding: 24px;

    .cart-header__icon {
      margin-right: 16px;
      color: var(--tr-primary);

      svg {
        height: 26px;
        width: auto;
      }

      img {
        height: 26px;
        width: auto;
      }
    }

    .cart-header__title {
      margin-top: 0;
      margin-bottom: 0;
      line-height: 26px;
      font-size: 24px;
      color: $primary;
      font-weight: bold;
    }
  }

  .single-trade__card-content {
    padding: 0 24px 24px;
    overflow: hidden;

    p,
    ul li,
    ol li {
      font-size: 1rem;
      line-height: 1.3;
    }

    p:last-child {
      margin-bottom: 0;
    }

    img {
      max-width: 100%;
      height: auto;
    }

    .card-content__subgroup {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      .card-content__subheader {
        margin-top: 0;
        margin-bottom: 4px;
        line-height: 30px;
        font-size: 18px;
        font-weight: bold;
        color: $gray-3;
        width: 100%;
        position: relative;
        z-index: 1;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          // transform: translateY(-50%);
          width: 100%;
          height: 2px;
          background: $gray-3;
          z-index: 2;
        }

        span {
          position: relative;
          z-index: 3;
          background: $white;
          padding-right: 24px;
        }
      }

      .single-trade__subgroup-table {
        width: 100%;
        border: 0;

        tbody {
          tr {
            vertical-align: baseline;

            td {
              padding: 8px 0;
              font-size: 1rem;
              line-height: 1.1;
              width: 50%;

              &:first-child {
                font-weight: bold;
                color: $text;
              }

              &:last-child {
                font-weight: normal;
                color: $black;
                padding-left: 5px;
              }
            }
          }
        }
      }
    }
  }

  .single-trade__card-notes {
    overflow: visible;

    .trade-notes {
      .trade-notes__list {
        .trade-notes__item {
          padding: 20px 0;
          border-radius: 0;
          border-bottom: 1px solid $gray-1;
          transition: all 0.3s ease;

          .trade-notes__note-date {
            font-weight: bold;
            color: $black;
            font-size: 1rem;
            line-height: 1.5rem;
            margin-bottom: 5px;
          }

          .trade-notes__note {
            p,
            ul li,
            ol li {
              font-size: 1rem;
              line-height: 1.2rem;
              color: $gray-6;
            }

            img {
              max-width: 100%;
              height: auto;
            }
          }

          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }
}
