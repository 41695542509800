@import './src/scss/mixins';

.auth_card {
  padding: 0;
  border: 0 !important;
  box-shadow: none !important;
  border-radius: 0;
  background: transparent;

  .auth_card__title {
    color: $black;
    line-height: 1;
    margin-top: 0;
    margin-bottom: 1.75rem;
    font-size: 1.75rem;
    font-weight: 600;
  }

  p {
    font-size: 1rem;
    line-height: 1.2;
    font-weight: normal;
    margin-bottom: 1.75rem !important;
    color: $black;
  }
}

body[data-theme='dark'] {
  .auth_card {
    .auth_card__title {
      color: $text-muted-dark;
    }

    p {
      color: $text-muted-dark;
    }
  }
}
