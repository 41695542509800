:root {
  --tr-primary: #007be8;
  --tr-primaryDark: #2c60e4;
  --tr-primaryLight: rgba(0, 123, 232, 0.1);
  --primary-light: #e5ecfe;
  --secondary-hover: #eff3fe;

  --tr-success: #00ab49;
  --tr-successDark: #009440;
  --tr-successLight: rgba(0, 171, 73, 0.1);
  --tr-warning: #eb9c00;
  --tr-warningDark: #d58e01;
  --tr-warningLight: rgba(235, 156, 0, 0.1);
  --tr-danger: #e60022;
  --tr-dangerDark: #c9021f;
  --tr-dangerLight: rgba(230, 0, 34, 0.1);

  --tr-layout-bg: #ffffff;
  --tr-sidebar-bg: #ffffff;

  --tr-sidebar-menu-highlight: rgb(242 246 246);

  --modal-overlay: rgba(76, 78, 100, 0.5);

  --tr-shadow-umbra-color: 47, 43, 61;

  --tr-primary-text-color: #000000;
  --tr-muted-text-color: #5d5d5b;

  --tr-border-color-gray-1: rgba(207, 219, 213, 0.6);
}

body[data-theme='dark'] {
  --tr-layout-bg: #000000;
  --tr-sidebar-bg: #000000;
  --primary-light: #1c2743;
  --secondary-hover: #2b3b66;

  --tr-sidebar-menu-highlight: rgb(25 25 25);

  --tr-primary-text-color: #ffffff;
  --tr-muted-text-color: rgba(255, 255, 255, 0.5);

  --modal-overlay: rgba(16, 17, 33, 0.5);

  --tr-shadow-umbra-color: 15, 20, 34;

  --tr-border-color-gray-1: rgba(207, 219, 213, 0.2);
}
