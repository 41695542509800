@import '../mixins';

.ant-drawer-header,
.ant-drawer-footer {
  border-color: $gray-2;
}

.ant-drawer-title {
  font-size: 1rem;
  font-weight: 600;
}

.tradiry-header {
  .ant-drawer-top {
    top: -30px;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
  }

  .ant-drawer-open {
    top: 59px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  .ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
    box-shadow: 0 16px 24px 0 rgba(34, 41, 47, 0.1);
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    overflow: hidden;
  }
}

@media (max-width: 780px) {
  .single-trade-drawer .ant-drawer-content-wrapper {
    width: 100% !important;
  }

  .ant-breadcrumb {
    margin-top: 30px;
  }
}
