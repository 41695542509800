@import './../mixins';

.tradiry-calendar {
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    display: none;
  }

  .tr-calendar__cell {
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;

      li {
        font-size: 0.8rem;
      }
    }
  }
}

.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date-today,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date-today {
  background: var(--tr-sidebar-menu-highlight);
}
