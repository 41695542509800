.periodWidget {
  height: auto;
  overflow-x: auto;

  table {
    width: 100%;
    vertical-align: middle;
    user-select: none;

    thead,
    tbody {
      tr th,
      tr td {
        cursor: default;

        &:first-child {
          width: 100px;
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }

    thead {
      tr {
        border-bottom: 1px solid var(--tr-border-color-gray-1);

        th {
          padding: 0 14px 20px;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: var(--tr-muted-text-color);
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid var(--tr-border-color-gray-1);

        td {
          padding: 24px 14px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--tr-primary-text-color);
          position: relative;

          strong {
            font-weight: 600;
          }

          .wrapper {
            position: relative;
            display: inline-block;
            display: flex;
          }

          .percents {
            margin-top: -16px;
            margin-left: 10px;
            padding: 2px 2px;
            border-radius: 6px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .icon {
              margin-right: 6px;

              svg {
                width: 12px;
                height: 12px;
                margin-top: -4px;
              }
            }

            .numbers {
              font-size: 10px;
              line-height: 1;
              color: var(--tr-success);
              font-weight: 500;
            }
          }

          .percentsDanger {
            @extend .percents;

            .numbers {
              color: var(--tr-danger);
            }
          }
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1700px) {
  .periodWidget {
    height: auto;

    table {
      tbody {
        tr {
          border-bottom: 1px solid var(--tr-border-color-gray-1);

          td {
            padding: 16px 7px;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .periodWidget {
    height: auto;

    table {
      tbody {
        tr {
          border-bottom: 1px solid var(--tr-border-color-gray-1);

          td {
            padding: 12px 7px;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }
}
