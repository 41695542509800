@import '../mixins';

.card {
  box-shadow: $default-card-shadow__dark;
  border-color: $dark-card-background;
  background: $dark-card-background;
}

.card__stat {
  background: $dark-card-background;

  .card-header {
    .card-title {
      color: $text-muted-dark;
    }
  }

  .card-content {
    .card-body {
      .card-content__stats {
        .card-content__stat {
          span {
            color: $text-muted-dark;
          }

          h3 {
            color: $text-muted-dark;
          }
        }
      }

      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}

.card__premium-unlock {
  border-color: $warning;

  .card-content {
    background: $warning;

    .card-body {
      h3 {
        color: $text-muted-dark;
      }

      p {
        color: $text-muted-dark;
      }
    }
  }
}
