@import '../mixins';

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left,
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 0;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: transparent;
}

.tr-menu__list {
  margin-top: 5px;
  padding-bottom: 50px;

  .ant-menu-item-group {
    .ant-menu-item-group-title {
      padding-left: 30px;
      padding-right: 30px;
      text-transform: uppercase;
      font-size: 0.7rem;
      font-weight: 600;
      color: $gray-4;
      letter-spacing: 1.5px;
    }
  }

  .ant-menu-item {
    a {
      padding-left: 15px;
      padding-right: 15px;
      color: $gray-7;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      font-size: 0.8rem;
      border-radius: 0;

      span {
        display: block;

        &:last-child {
          font-size: 1.1rem;
        }
      }
    }
  }

  .ant-menu-item-active,
  .ant-menu-item-selected {
    background-color: $gray-0 !important;

    a {
      color: $primary;
    }
  }

  .ant-menu-submenu {
    padding: 0 15px;
    margin-top: 4px;
    margin-bottom: 8px;

    .ant-menu-submenu-title {
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      padding-right: 15px;

      span,
      i {
        display: block;
        color: $gray-7 !important;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        font-size: 0.8rem;
        border-radius: 0;
      }

      span {
        order: 1;
      }

      .fe {
        order: 3;
        margin-left: auto;
        font-size: 1.1rem;
      }

      i {
        order: 2;
        right: 40px;
      }
    }

    &:hover {
      background-color: $gray-0 !important;

      .ant-menu-submenu-title span,
      i {
        color: $primary !important;
      }
    }

    ul {
      background: transparent !important;

      li {
        padding-right: 0 !important;
        padding-left: 0 !important;
        height: auto !important;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        background: transparent !important;

        a span {
          font-size: 0.8rem !important;
          padding-left: 15px;
        }

        a,
        a:hover {
          background: transparent !important;
        }
      }
    }
  }

  .ant-menu-submenu-open {
    background: $gray-0 !important;
  }
}

.logo_txt {
  cursor: pointer;
}

.tr__sidebar-menu-wrapper.ant-layout-sider-collapsed {
  overflow-x: hidden;

  .tr_sidebar__outer_controller {
    max-width: 80px;
    position: relative;
  }

  .ant-menu-item-group,
  .menu-item__title {
    display: none !important;
  }

  .ant-menu-item,
  .ant-menu-submenu,
  .ant-menu-submenu-title {
    line-height: 1;
    height: auto;
  }

  .ant-menu-item,
  .ant-menu-item a,
  .ant-menu-submenu,
  .ant-menu-submenu-title {
    padding: 0;
    margin: 0;
  }

  .ant-menu-submenu .ant-menu-submenu-title .fe {
    order: 1 !important;
    margin-left: 0 !important;
  }

  .ant-menu-item a,
  .ant-menu-submenu .ant-menu-submenu-title {
    text-align: center;
    justify-content: center;
    padding: 12px 0;
  }

  .ant-menu-item a .fe,
  .ant-menu-submenu .ant-menu-submenu-title .fe {
    font-size: 1.6rem;
  }

  .tr_sidebar-logo {
    padding: 0;
    text-align: center;
    position: relative;
    cursor: pointer;

    .logo_txt {
      display: none;
    }

    &:after {
      content: 'tr.';
    }
  }
}

.ant-menu-inline-collapsed-tooltip .fe {
  display: none !important;
}

.tr-menu__list .ant-menu-item a span.premium-badge:last-child {
  border: 1px solid $warning !important;
  padding: 2px 4px;
  border-radius: 2px;
  font-size: 11px;
  line-height: 1;
  color: $warning !important;
}
