.headStats {
  .title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    color: var(--tr-muted-text-color);
    margin-bottom: 8px;

    svg {
      margin-top: -2px;
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }
  }

  .content {
    font-size: 20px;
    line-height: 22px;
    font-weight: 600;
    color: var(--tr-primary-text-color);
    margin-right: 8px;
  }
}
