.generalInsights {
  .title {
    margin-bottom: 22px;

    p {
      margin-bottom: 0;
      font-weight: 500;
      font-size: 16px;
      line-height: 1;
      color: var(--tr-primary);
    }

    h3 {
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      color: var(--tr-muted-text-color);
    }
  }

  .comingSoon {
    height: 300px;
  }
}
