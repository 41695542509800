@import '../mixins';

.list-group {
  .list-group-item {
    cursor: default;
    color: $gray-6;
    transition: all 0.3s ease;

    .text-muted {
      color: $gray-5 !important;
    }

    small {
      font-size: 0.78rem;
    }

    .list-item-value {
      font-weight: 600;
      color: $dark-gray-3;
    }

    .ant-progress-text {
      color: $gray-6;
    }

    .list-icon {
      .avatar {
        color: $primary;
        background-color: rgba(90, 141, 238, 0.2) !important;
        font-size: 1rem;
      }
    }

    &:hover {
      background-color: $gray-1;
      transition: all 0.3s ease;

      .ant-progress-inner {
        background-color: $white;
      }
    }
  }

  &__no-hover {
    .list-group-item {
      &:hover {
        background-color: transparent;
      }
    }
  }

  &__compact {
    .list-group-item {
      padding: 6px 0;
    }
  }

  &__stats {
    .list-group-item {
      border-bottom: 1px solid $gray-0 !important;

      &:last-child {
        border-bottom: 0 !important;
      }

      .list-item-value {
        font-size: 0.95rem;
      }
    }
  }
}
