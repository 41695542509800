@import '../mixins';

.group-fullwidth {
  width: 100%;
  display: flex;

  label {
    flex: 1 1 100%;
    text-align: center;
  }
}

.ant-radio-button-wrapper {
  border-color: $gray-2 !important;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: $primary !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: $primary !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.ant-radio-button-wrapper:hover {
  color: $primary !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: $primary !important;
  color: $white !important;
}
