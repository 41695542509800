// Colors
$white: #fff;
$black: #000;
$blue: #007be8;
$blue-light: #42baf9;
$blue-light2: lighten($blue, 5%);
$blue-dark: #2c60e4;
$gray-0: rgba(242, 244, 249, 0.5);
$gray-1: #f2f4f8;
$gray-2: #e4e8f1;
$gray-3: #d9dee9;
$gray-4: #c8c4db;
$gray-5: #a1a1c3;
$gray-6: #47525e;
$gray-7: #585a96;
$yellow: #ff0;
$orange: #eb9c00;
$red: #e60022;
$pink: #fd3995;
$purple: #6859de;
$green: #00ab49;

$body-background: #f0f2f5;
$body-background2: #f8f8f8;

$dark-body-background: #161d31;
$dark-card-background: #283046;
$dark-form-border: #404656;

$text: $black;
$text-muted: #808080;
$text-muted-dark: #d0d2d6;
$border: $gray-2;

// Accent colors
$default: $gray-4;
$primary: $blue;
$secondary: $gray-5;
$success: $green;
$info: $blue-light;
$warning: $orange;
$danger: $red;
$light: $gray-1;
$dark: $black;
$dark2: $black;

// dark theme
$dark-gray-1: #aeaee0;
$dark-gray-2: #7575a3;
$dark-gray-3: #4f4f7a;
$dark-gray-4: #232135;
$dark-gray-5: #141322;
$dark-gray-6: #0c0c1b;

// Font Family
$base-font-family: 'Poppins', 'Roboto', 'Arial', sans-serif;

// Font Size
$base-font-size: 14px !default;

// Borders
$form-border-radius: 0.357rem;

// Shadows
$shadow: 0 0 40px -10px rgba($black, 0.2);
$shadow-2: 0 4px 38px 0 rgba($black, 0.11), 0 0 21px 0 rgba($black, 0.05);
$shadow-3: 0 0 100px -30px rgba(57, 55, 73, 0.3);
$shadow-4: 0 4px 10px 0 rgba($black, 0.03), 0 0 10px 0 rgba($black, 0.02);
$shadow-5: 0 0 40px -10px rgba($black, 0.4);
$shadow-6: 0 4px 10px 0 rgba(20, 18, 34, 0.028), 0 0 10px 0 rgba(20, 19, 33, 0.017);
$shadow-ant: 0 10px 35px -5px rgba(0, 0, 0, 0.15);
$jira-card: 0 3px 5px rgba(22, 22, 53, 0.16);
$form-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
$default-card-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
$default-card-shadow__dark: 0 0 15px 0 rgba(34, 41, 47, 0.05);

// Convert value of rem() sass mixin function
@function rem($px, $base: $base-font-size) {
  @return #{floor(($px/$base) * 100) / 100}rem; // to REMs
  // @return #{$px}px; // to PX's
}

// Transitions
@mixin transition-bg() {
  transition: background 0.2s ease-in-out;
}
@mixin transition-color() {
  transition: color 0.2s ease-in-out;
}
@mixin transition-fast() {
  transition: all 0.05s ease-in-out;
}
@mixin transition-middle() {
  transition: all 0.1s ease-in-out;
}
@mixin transition-slow() {
  transition: all 0.2s ease-in-out;
}

$default_transition: all 0.2s ease-in;

// Responsive utils
$xxl-min-width: 1600px;
$xxl-max-width: 1599px;
$xl-min-width: 1200px;
$xl-max-width: 1199px;
$lg-min-width: 992px;
$lg-max-width: 991px;
$md-min-width: 768px;
$md-max-width: 767px;
$sm-min-width: 576px;
$sm-max-width: 575px;
