.h-300 {
  height: 300px;
}
.h-400 {
  height: 400px;
}

.m-h-90 {
  min-height: 90px;
}

.m-h-150 {
  min-height: 150px;
}

.m-h-200 {
  min-height: 200px;
}

.m-h-300 {
  min-height: 300px;
}

.m-h-500 {
  min-height: 500px;
}

.m-h-800 {
  min-height: 800px;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.h-100 {
  height: 100%;
}

.limit-height-300 {
  max-height: 300px;
}

.limit-height-400 {
  max-height: 400px;
}

.limit-height-500 {
  max-height: 500px;
}
