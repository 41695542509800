@import './../mixins';

@import 'alert';
@import 'avatar';
@import 'card';
@import 'type';
@import 'drawer';
@import 'breadcrumbs';
@import 'list-group';
@import 'quill';
@import 'colors';
@import 'antd/index';
@import 'jira-cards';
@import 'modal';
@import 'advanced-stats';
@import 'buttons';
@import 'subscription';
@import 'pagination';

.ant-empty-img-default-path-3,
.ant-empty-img-default-ellipse,
.ant-empty-img-default-path-2 {
  fill: var(--tr-border-color-gray-1);
}

.ant-picker-calendar,
.ant-picker-calendar-full .ant-picker-panel {
  background: var(--tr-layout-bg);
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  color: var(--tr-muted-text-color);
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  border-color: var(--tr-muted-text-color);
}

.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected
  .ant-picker-calendar-date-today,
.ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-cell-selected:hover
  .ant-picker-calendar-date-today {
  fill: var(--tr-border-color-gray-1);
  border-color: $primary;
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(
    .ant-picker-cell-range-start
  ):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(
    .ant-picker-cell-range-hover-end
  )
  .ant-picker-cell-inner {
  background: var(--tr-layout-bg);
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  background: var(--tr-layout-bg);
}

.ant-radio-group,
.ant-progress-text {
  border-color: var(--tr-muted-text-color);
}

.ant-radio-button-wrapper {
  background: $dark-card-background;
  border-color: var(--tr-muted-text-color);
  border-color: $dark-form-border !important;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  background: $dark-form-border;
}

.ant-dropdown {
  color: $text-muted-dark;
}

.ant-dropdown-menu {
  background: $dark-card-background;
}

.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  background: $text-muted-dark;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background: $dark-form-border;
}

.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  color: $text-muted-dark;
}

.ant-progress-inner {
  background: $dark-form-border;
  overflow: hidden;
}

.ant-notification-hook-holder,
.ant-notification-notice {
  background: $dark-card-background;
  box-shadow: $default-card-shadow__dark;
}

.ant-notification-notice-close,
.ant-notification-notice-with-icon .ant-notification-notice-description,
.ant-notification-notice-message {
  color: $text-muted-dark;
}
