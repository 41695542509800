@import './src/scss/mixins';

.accounts_list__item {
  background: $white;
  margin-bottom: 24px;
}

.accounts_list_item__info_text {
  font-size: 0.95rem;
  line-height: 1;
  margin-bottom: 8px;
  font-weight: 400;
  color: $text-muted;
}

body[data-theme='dark'] {
  .accounts_list__item {
    background: $dark-card-background;
  }

  .accounts_list_item__info_text {
    color: $text-muted-dark;
  }
}
