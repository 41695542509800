@import '../mixins';

.ant-picker-input > input,
.ant-picker {
  border-color: $gray-2;
  border-radius: $form-border-radius;
}

.ant-picker-input > input:focus,
.ant-picker-input > .input-focused,
.ant-picker-focused {
  border-color: $primary;
  outline: 0;
  box-shadow: none;
}

.ant-picker-focused {
  box-shadow: $form-shadow;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: $primary;
}

.ant-picker-suffix {
  color: $gray-2;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid $primary;
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
  .ant-picker-cell-inner {
  background: $gray-2;
}

.ant-picker-header-view button:hover {
  color: $primary;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: $primary;
}
