@import './src/scss/mixins';

.appHeader {
  position: fixed;
  top: 0;
  left: 260px;
  right: 0;
  width: calc(100% - 260px);
  z-index: 12;
  background-color: var(--tr-layout-bg);
  border-bottom: 1px solid var(--tr-border-color-gray-1);

  .appHeaderContainer {
    display: block;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
  }

  .headerContent {
    padding: 0.8rem 0;

    .headerLeftMenu {
      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        li {
          &:first-child {
            display: none;
          }

          a {
            display: block;
            text-decoration: none;
            outline: none;
            color: $text-muted;
            line-height: 1;
            font-size: 1.6rem;
            padding: 0 0.6rem;

            &:hover {
              color: $primary;
            }
          }

          a.search {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .icon {
              margin-right: 12px;
            }

            .searchPlaceholder {
              color: var(--tr-muted-text-color);
              font-size: 16px;
              line-height: 1;
              font-weight: 400;
              letter-spacing: 0.1px;
            }

            &:hover {
              .searchPlaceholder {
                color: var(--tr-primary);
              }
            }
          }

          .filtersApplied {
            color: $primary;
          }

          &:first-child a {
            padding-left: 0;
          }

          &:last-child a {
            padding-right: 0;
          }

          sup {
            top: 7px;
            right: 1px;
          }
        }
      }
    }
  }

  .headerRightMenu {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .upgradeAccount,
    .darkLightMode {
      text-decoration: none;
      outline: none;
    }

    .upgradeAccount {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: $warning;
      line-height: 1;

      span,
      svg {
        display: block;
      }

      span {
        font-size: 1rem;
      }

      svg {
        margin-right: 0.4rem;
        line-height: 1;
        font-size: 1.65rem;
      }
    }

    .darkLightMode {
      color: $text-muted;
      line-height: 1;
      font-size: 1.6rem;
    }
  }
}

//body[data-theme='dark'] {
//  .appHeader {
//    background: $dark-card-background;
//    box-shadow: $default-card-shadow__dark;
//
//    .headerContent {
//      .headerLeftMenu {
//        ul {
//          li {
//            a {
//              color: $text-muted-dark;
//
//              &:hover {
//                color: $primary;
//              }
//            }
//
//            .filtersApplied {
//              color: $primary;
//            }
//          }
//        }
//      }
//    }
//
//    .headerRightMenu {
//      .darkLightMode {
//        color: $text-muted-dark;
//
//        &:hover {
//          color: $primary;
//        }
//      }
//    }
//  }
//}

@media screen and (max-width: 1100px) {
  .appHeader {
    left: 0;
    width: 100%;

    .appHeaderContainer {
      .headerContent {
        padding-left: 20px;
        padding-right: 20px;

        .headerLeftMenu {
          ul {
            li {
              &:first-child {
                display: block;
              }

              a {
                padding: 0 0.75rem;
                font-size: 2.2rem;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .appHeader {
    .appHeaderContainer {
      .headerContent {
        padding-left: 20px;
        padding-right: 20px;

        .headerLeftMenu {
          ul {
            li {
              a.search {
                .icon {
                  margin-right: 0;
                }

                .searchPlaceholder {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
