@import '../mixins';

.subscription-promo {
  .subscription-promo__details,
  .subscription-promo__subscribe-block {
    h2 {
      color: $text-muted-dark;

      strong {
        &:before {
          background: $primary;
        }
      }
    }

    .bt-1 {
      border-top: 1px solid $text-muted-dark;
    }
  }

  .subscription-promo__subscribe-block {
    background: $dark-card-background;
    border: 1px solid $dark-card-background;
    box-shadow: $default-card-shadow__dark;

    p {
      color: $text-muted-dark;
    }

    table {
      tr {
        border-bottom: 1px solid $text-muted-dark;
      }
    }
  }
}
