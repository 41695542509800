.instrumentsWidget {
  position: relative;

  .inner {
    position: relative;
    height: 316px;

    .item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 14px;

      &:last-child {
        margin-bottom: 0;
      }

      .iconWrapper {
        margin-right: 14px;

        .icon {
          position: relative;
          width: 42px;
          height: 42px;
          border-radius: 6px;
          background-color: var(--tr-primaryLight);

          span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: var(--tr-primary);
            font-weight: 500;
            font-size: 18px;
            line-height: 1;
          }
        }
      }

      .name {
        letter-spacing: 0.1px;

        h4 {
          margin-top: 0;
          margin-bottom: 4px;
          font-size: 14px;
          line-height: 16px;
          font-weight: 500;
          color: var(--tr-primary-text-color);
        }

        p {
          font-size: 12px;
          line-height: 14px;
          color: var(--tr-muted-text-color);
          font-weight: 400;
          margin-bottom: 0;
        }
      }

      .info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: auto;

        .percents {
          color: var(--tr-primary);
          font-weight: 600;
          font-size: 14px;
          line-height: 1;
        }

        .progress {
          margin-left: 14px;
        }
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1700px) {
  .instrumentsWidget .inner {
    height: 230px;
  }
}
