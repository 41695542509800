.SimpleStocksList {
  position: relative;

  .Title {
    margin-top: 0;
    margin-bottom: 40px;
    font-weight: 800;
    font-size: 28px;
    line-height: 40px;
    color: var(--tr-primary-text-color);
    text-align: center;
  }

  .ListWrapper {
    padding: 0;

    .Header {
      background: var(--tr-sidebar-bg);
      box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
      mix-blend-mode: normal;
      opacity: 0.7;
      border-radius: 8px;
      padding: 24px;
      margin-bottom: 12px;

      div [data-label='true'] {
        font-weight: 800;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.2px;
        color: var(--tr-primary-text-color);
      }
    }

    .Content {
      position: relative;

      .Item {
        display: block;
        color: var(--tr-primary-text-color);
        width: 100%;
        padding: 12px 24px;
        mix-blend-mode: normal;
        box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
        background: var(--tr-sidebar-bg);
        border-radius: 6px;
        margin-bottom: 12px;

        .Instrument {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          &__Name {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: var(--tr-muted-text-color);
            transition: all 0.3s ease-in-out;

            span {
              font-weight: 700;
              color: var(--tr-primary-text-color);
              padding-right: 4px;
              transition: all 0.3s ease-in-out;
            }
          }

          &__Image {
            position: relative;
            flex: 0 0 26px;
            width: 26px;
            height: 26px;
            background: var(--tr-border-color-gray-1);
            border-radius: 50%;
            overflow: hidden;
            margin-right: 14px;

            &__Wrapper {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 20px;
              height: 20px;
              border-radius: 50%;
              overflow: hidden;

              img {
                position: absolute !important;
                top: 50% !important;
                left: 50% !important;
                transform: translate(-50%, -50%) !important;
                width: 100% !important;
                height: auto !important;
              }
            }
          }
        }

        div[data-value='true'] {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: var(--tr-primary);
        }

        div[data-value='true'][data-trend='up'] {
          color: var(--tr-success);
        }

        div[data-value='true'][data-trend='down'] {
          color: var(--tr-danger);
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          .Instrument {
            &__Name {
              color: var(--tr-primary);
              transition: all 0.3s ease-in-out;

              span {
                color: var(--tr-primary);
                transition: all 0.3s ease-in-out;
              }
            }
          }
        }
      }
    }
  }

  .ExploreAll {
    text-align: center;
    margin-top: 30px;

    a {
      padding: 16px 24px;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: var(--tr-primary);
      transition: all 0.3s ease-in-out;

      svg {
        margin-top: -2px;
        margin-right: 8px;

        path {
          fill: var(--tr-primary);
          transition: all 0.3s ease-in-out;
        }
      }

      &:hover {
        color: var(--tr-primary-text-color);
        transition: all 0.3s ease-in-out;

        svg path {
          fill: var(--tr-primary-text-color);
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
}
