@import '../mixins';

.ant-card {
  border-color: $gray-2;
  border-width: 0;
  border-radius: 0.428rem;
  box-shadow: $default-card-shadow;
  transition: all 0.3s ease-in-out, background 0s, color 0s, border-color 0s;
}

.ant-card-head-title {
  font-size: 1rem;
  font-weight: 600;
}
