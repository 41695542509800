@import '../mixins';

.advanced-stats {
  .as-menu__wrapper {
    .as-menu__item {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-radius: 4px;
      user-select: none;
      margin-bottom: 10px;
      cursor: pointer;
      transition: $default_transition;

      &:last-child {
        margin-bottom: 0;
      }

      .item__name {
        cursor: pointer;
        font-weight: 600;
        color: $dark-gray-5;
        font-size: 1.1rem;
        line-height: 1;
        flex: 1 0 auto;
        padding: 8px 6px 8px 12px;
      }

      .item__actions {
        padding: 8px 12px 8px 6px;
        display: flex;
        justify-content: flex-end;

        a {
          display: block;
          text-align: center;
          padding: 4px;
        }
      }
    }

    .active,
    .as-menu__item:hover {
      background: $gray-2;
      transition: $default_transition;
    }
  }

  .advanced-stat__values {
    .ant-card-head {
      border-bottom: 0;
    }

    .advanced-stat__values-list {
      padding: 0;
      margin: 0;
      list-style-type: none;

      .advanced-stat__values-item {
        padding: 10px 0;
        margin-bottom: 0;
        border-bottom: 1px solid $gray-2;

        .advanced-stat__item_name {
          font-size: 1rem;
          line-height: 1;
        }

        &:last-child {
          border-bottom: 0 !important;
        }
      }
    }
  }
}
