.ant-popover-inner {
  background-color: var(--tr-sidebar-menu-highlight);
  box-shadow: none;
  border-radius: 6px;
}

.ant-popover-arrow {
  display: none;
}

.ant-popover-inner-content {
  width: 100%;
  max-width: 240px;
  font-size: 14px;
  line-height: 16px;
  color: var(--tr-primary-text-color);
}
