@import '../mixins';

.jira-card {
  background-color: $dark-card-background;
  box-shadow: $default-card-shadow__dark;

  &__title {
    color: $text-muted-dark;
  }
}
