.balancesWidget {
  padding: 20px 0;

  .item {
    display: flex;

    .icon {
      width: 48px;
      height: 48px;
      background: var(--tr-primaryLight);
      border-radius: 8px;
      position: relative;

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 24px;
        height: 24px;
        stroke: var(--tr-primary);
      }
    }

    .content {
      margin-left: 22px;

      .title {
        margin: 0;
        line-height: 22px;
        font-size: 18px;
        font-weight: 600;
        color: var(--tr-primary-text-color);
      }

      .subTitle {
        line-height: 22px;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.1px;
        color: var(--tr-primary-text-color);
        margin-bottom: 0;
      }
    }
  }

  .itemDanger {
    .icon {
      background: var(--tr-dangerLight);

      svg {
        stroke: var(--tr-danger);
      }
    }
  }

  .itemSuccess {
    .icon {
      background: var(--tr-successLight);

      svg {
        stroke: var(--tr-success);
      }
    }
  }

  .itemWarning {
    .icon {
      background: var(--tr-warningLight);

      svg {
        stroke: var(--tr-warning);
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .balancesWidget {
    padding-top: 0;

    .item {
      margin-top: 14px;

      .icon {
        flex: 0 0 48px;
      }

      .content {
        margin-left: 18px;

        .title {
          margin: 0;
          line-height: 18px;
          font-size: 14px;
          font-weight: 600;
          color: var(--tr-primary-text-color);
        }

        .subTitle {
          line-height: 12px;
          font-size: 10px;
          color: var(--tr-muted-text-color);
        }
      }
    }
  }
}
