@import './../mixins';

.badge {
  font-size: 80%;
}

.badge-success {
  background-color: $success;
}

.badge-danger {
  background-color: $danger;
}

.badge-warning {
  background-color: $warning;
  color: #fff;
}
