@import './../mixins';

.ant-btn-loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  span {
    display: block;
  }
}

.ant-btn,
.btn {
  border-radius: $form-border-radius !important;
  border-color: $gray-2;
  box-shadow: 0 4px 25px -12px transparent;

  &:hover {
    border-color: $primary;
    color: $primary;
    box-shadow: 0 4px 25px -12px transparent;
  }
}

.ant-btn-primary,
.btn-primary {
  border-color: $primary;
  background-color: $primary;
  color: $white;

  &:hover {
    background-color: $blue-light2;
    border-color: $blue-light2;
    color: $white;
    box-shadow: 0 4px 25px -12px $blue-light2;
  }
}

.ant-btn-primary__light,
.btn-primary__light {
  border-color: var(--tr-primaryLight);
  background-color: var(--tr-primaryLight);
  color: $white;

  &:hover {
    background-color: var(--tr-primary);
    border-color: var(--tr-primary);
    color: $white;
    box-shadow: 0 4px 25px -12px var(--tr-primary);
  }
}

.ant-btn-success,
.btn-success {
  border-color: $success;
  background: $success;
  color: $white;

  &:hover {
    border-color: lighten($success, 10%);
    background: lighten($success, 10%);
    color: $white;
    box-shadow: 0 4px 25px -12px $success;
  }
}

.ant-btn-success__light,
.btn-success__light {
  border-color: var(--tr-successLight);
  background-color: var(--tr-successLight);
  color: var(--tr-success);

  &:hover {
    background-color: var(--tr-success);
    border-color: var(--tr-success);
    color: $white;
    box-shadow: 0 4px 25px -12px var(--tr-success);
  }
}

.ant-btn-warning,
.btn-warning {
  border-color: $warning;
  background: $warning;
  color: $white;

  &:hover {
    border-color: darken($warning, 5%);
    background: darken($warning, 5%);
    color: $white;
    box-shadow: 0 4px 25px -12px $warning;
  }
}

.ant-btn-danger,
.btn-danger {
  border-color: $danger;
  background: $danger;
  color: $white;

  &:hover {
    border-color: lighten($danger, 10%);
    background: lighten($danger, 10%);
    color: $white;
    box-shadow: 0 4px 25px -12px $danger;
  }
}

.ant-btn-dangerous,
.btn-dangerous {
  color: $danger;
  background-color: $white;
  border-color: $danger;

  &:hover {
    border-color: $danger;
    color: $white;
    background: $danger;
    box-shadow: 0 4px 25px -12px $danger;
  }
}

.btn-link {
  color: $black;
  border: 0 !important;

  &:hover {
    color: $primary;
  }
}

.ant-btn-text__extended {
  padding: 0;
  border: 0;
  outline: none;

  &:hover {
    background: transparent;
  }
}

.btn-white {
  border-color: $warning;
  background: #fff;
  color: $warning;
  transition: all ease 0.3s;

  &:hover {
    color: #fff;
    border-color: #fff;
    background: transparent;
    transition: all ease 0.3s;
  }
}
