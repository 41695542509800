.Executions {
  padding: 8px 0;
  border: 1px solid var(--tr-border-color-gray-1);
  border-radius: 6px;
  position: relative;

  .extend {
    position: absolute;
    width: 100%;
    left: 0;
    top: calc(100% - 9px);
    text-align: center;
    z-index: 3;

    button {
      box-shadow: none;
      border: none;
      color: #ffffff;
      background: var(--tr-primary);
      font-size: 12px;
      line-height: 14px;
      padding: 2px 16px;
      border-radius: 8px;
    }
  }

  .Execution {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    padding: 6px 20px;
    border-top: 1px solid var(--tr-border-color-gray-1);
    position: relative;
    z-index: 2;

    &__Item {
      flex: 0 0 25%;
      padding-right: 12px;

      .title {
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: var(--tr-muted-text-color);
      }

      .value {
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: var(--tr-primary-text-color);
      }

      .action {
        @extend .value;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .dot {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: var(--tr-danger);
          margin-right: 6px;
        }

        .dot[data-action='1'] {
          background: var(--tr-success);
        }
      }

      &:last-child {
        padding-right: 0;
      }
    }

    &:first-child {
      border-top: 0;
    }
  }
}

@media screen and (max-width: 570px) {
  .Executions {
    padding: 8px 0;
    border: 1px solid var(--tr-border-color-gray-1);
    border-radius: 6px;

    .Execution {
      padding: 6px 12px;
      flex-wrap: wrap;

      &__Item {
        flex: 0 0 50%;
        padding-right: 8px;
        margin-bottom: 8px;

        &:nth-child(3),
        &:nth-child(4) {
          margin-bottom: 0;
        }

        &:nth-child(2),
        &:nth-child(4) {
          padding-right: 0;
        }

        .title {
          font-size: 13px;
          line-height: 16px;
        }

        .value {
          font-size: 15px;
          line-height: 22px;
        }
      }
    }
  }
}
