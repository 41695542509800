@import '../../mixins';

.ant-card {
  border-color: $dark-card-background;
  background: $dark-card-background;
}

.ant-card-head-title {
  color: $text-muted-dark;
}

.ant-card-head {
  border-color: $text-muted-dark;
}
