.resultsWidget {
  position: relative;

  .responsive {
    position: relative;
  }

  table {
    width: 100%;
    vertical-align: center;

    thead {
      tr {
        th {
          padding: 0 10px 10px;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: var(--tr-muted-text-color);

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 10px;
          letter-spacing: 0.1px;
          -moz-osx-font-smoothing: grayscale;
          -webkit-font-smoothing: antialiased;
          font-size: 14px;
          line-height: 16px;
          color: var(--tr-primary-text-color);
          font-weight: 400;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }

        .name {
          // width: 200px;

          h4 {
            margin: 0;
            font-weight: 600;
            color: var(--tr-primary-text-color);
            font-size: 14px;
            line-height: 18px;
          }

          p {
            margin-bottom: 0;
            font-size: 14px;
            line-height: 16px;
            color: var(--tr-muted-text-color);

            small {
              font-size: 11px;
              font-weight: 400;
              line-height: 1;
            }
          }
        }

        .result {
          font-size: 14px;
          line-height: 1;
          color: var(--tr-primary);
          font-weight: 600;
          white-space: nowrap;
        }

        .percentage {
          color: var(--tr-muted-text-color);
          font-size: 14px;
          white-space: nowrap;
        }

        .percentageSuccess {
          @extend .percentage;
          color: var(--tr-success);
        }

        .percentageDanger {
          @extend .percentage;
          color: var(--tr-danger);
        }

        .chart {
          padding: 0;
          width: 90px;
          position: relative;
          overflow: hidden;

          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0);
            backdrop-filter: blur(3px);
            z-index: 9;
          }

          .chartWrapper {
            margin: 10px 0;
            height: 36px;
            overflow: hidden;
          }
        }
      }
    }
  }
}

@media (min-width: 1190px) and (max-width: 1700px) {
  .resultsWidget {
    table {
      thead {
        tr {
          th {
            font-size: 10px;
            white-space: nowrap;
            line-height: 1;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .resultsWidget {
    table {
      thead {
        tr {
          th {
            padding: 0 8px 8px;
            white-space: nowrap;
          }
        }
      }

      tbody {
        tr {
          td {
            font-size: 12px;
            line-height: 14px;
          }

          .name {
            width: 150px;
            white-space: pre-wrap;

            h4 {
              font-weight: 600;
              font-size: 12px;
              line-height: 16px;
            }

            p {
              font-size: 12px;
              line-height: 14px;

              small {
                font-size: 10px;
              }
            }
          }

          .result {
            font-size: 12px;
          }

          .percentage {
            font-size: 12px;
          }

          .chart {
            width: 70px;

            .chartWrapper {
              margin: 6px 0;
              height: 36px;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 570px) {
  .resultsWidget .responsive {
    overflow-x: auto;

    table tbody tr .name {
      white-space: nowrap;
    }
  }
}
