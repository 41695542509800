@import '../mixins';

.ant-layout.ant-layout-has-sider {
  min-height: 100vh;
}

.ant-layout,
.ant-layout-footer {
  background: transparent !important;
}

.tr-layout {
  background: $white !important;
}
