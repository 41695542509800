@import '../mixins';

.form-upload {
  .form-upload__wrapper {
    position: relative;
    width: 100%;
    height: 200px;

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 5;
      left: 0;
      top: 0;
      cursor: pointer;
    }

    .form-upload__content {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      border: 2px dashed $gray-1;
      left: 0;
      top: 0;

      h5 {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: 375px;
        text-align: center;
        color: $gray-4;
        font-size: 1.1rem;
        margin-top: 0;
        margin-bottom: 0;
        line-height: 1.4;
      }
    }
  }
}
