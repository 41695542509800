.totalReturn {
  .title {
    margin-top: 0;
    margin-bottom: 30px;
    line-height: 22px;
    letter-spacing: 0.1px;
    font-size: 18px;
    font-weight: 500;
    color: var(--tr-primary-text-color);
  }

  .label {
    margin-bottom: 8px;

    small {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.1px;
    }
  }

  .value {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.1px;
    margin: 0 0 14px;
    color: var(--tr-primary-text-color);
  }

  .extra {
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.1px;
    margin-bottom: 0;
    color: var(--tr-muted-text-color);
  }
}
