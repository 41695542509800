.tradeNotes {
  .list {
    margin-bottom: 24px;

    .note {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      .info {
        margin-bottom: 6px;
        font-size: 13px;
        line-height: 1;
        color: var(--tr-muted-text-color);
      }

      .description {
        p,
        ul li,
        ol li {
          margin-bottom: 6px;
          font-size: 14px;
          line-height: 20px;
          color: var(--tr-primary-text-color);

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .form {
    border: 1px solid var(--tr-border-color-gray-1);
    padding: 16px;
    border-radius: 6px;
  }
}
