@import './src/scss/mixins';

body {
  .trSidebar {
    // box-shadow: 0 0 110px -35px rgba(56, 53, 74, 0.15);
    border-right: 1px solid var(--tr-border-color-gray-1);
    position: relative;
    height: 100%;
    padding-bottom: 80px;

    .trSidebarLogo {
      user-select: none;
      padding: 34px 30px;
      line-height: 1;
      font-size: 2.2rem;
      font-weight: 700;
      color: var(--tr-primary);

      svg {
        height: 44px;
        width: auto;
      }
    }

    .trSideMenu {
      list-style-type: none;
      padding: 10px 20px;
      margin: 0;

      li {
        a {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: 14px 10px;
          border-radius: 8px;
          line-height: 18px;
          font-size: 14px;
          font-weight: 600;
          color: var(--tr-muted-text-color);
          user-select: none;
          background: transparent;
          box-shadow: 0 0 10px 1px transparent;
          transition: all 0.3s ease;

          &:hover {
            color: var(--tr-primary-text-color);
            transition: all 0.3s ease;

            .menuItemIcon {
              svg {
                path {
                  fill: var(--tr-primary-text-color);
                  transition: all 0.3s ease;
                }
              }
            }
          }

          .menuItemName {
            user-select: none;

            sup {
              color: var(--tr-success);
              margin-left: 4px;
            }
          }

          .menuItemIcon {
            margin-right: 0.5rem;
            margin-top: -3px;

            svg {
              height: 20px;
              width: auto;

              path {
                fill: var(--tr-muted-text-color);
              }
            }
          }

          .menuItemExpandIcon {
            margin-left: auto;

            svg {
              transition: all 0.3s ease-in-out;
            }
          }
        }

        a[data-open='yes'] {
          .menuItemExpandIcon {
            svg {
              transform: rotate(-180deg);
              transition: all 0.3s ease-in-out;
            }
          }
        }

        .activeMenuItem {
          color: var(--tr-primary-text-color) !important;
          background: var(--tr-sidebar-menu-highlight);
          transition: all 0.3s ease;

          .menuItemIcon {
            svg {
              path {
                fill: var(--tr-primary-text-color) !important;
              }
            }
          }
        }
      }

      > li {
        > a {
          text-decoration: none;
        }

        .trSideSubMenu {
          list-style-type: none;
          margin: 0;
          padding: 0 0 0 30px;
          height: 0;
          opacity: 0;
          overflow: hidden;
          transition: all 0.4s ease-in-out;

          > li {
            > a {
              text-decoration: none;
              line-height: 18px;
              font-size: 14px;
              font-weight: 600;
              padding: 10px;

              .menuItemIcon {
                font-size: 12px;
                margin-top: -3px;
              }
            }
          }
        }

        .trSideSubMenuOpen {
          height: auto;
          opacity: 1;
          transition: all 0.4s ease-in-out;
        }
      }
    }
  }
}

body[data-theme='dark'] {
  .trSidebar {
    box-shadow: 0 0 15px 0 rgba(34, 41, 47, 0.05);

    .trSidebarLogo {
      color: $text-muted-dark;
    }

    .trSideMenu {
      li {
        a {
          color: $text-muted-dark;

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
}
