@import './../../scss/mixins';

.mainLayout {
  min-height: 100vh;
  height: 100%;
  background-color: var(--tr-layout-bg);
  position: relative;
  z-index: 1;

  .mobileMenuOverlay {
    display: none;
    position: fixed;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.23);
    z-index: 4;
    opacity: 0;
    transition: left 0s ease, opacity 0.15s ease;
  }

  .mainLayoutSidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 260px;
    height: 100%;
    z-index: 5;
    backface-visibility: hidden;
    background-color: var(--tr-layout-bg);
  }

  .mainLayoutContent {
    padding-left: 260px;
    position: relative;
    z-index: 3;
    min-height: 100vh;

    .headerOverlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 102px;
      padding-top: 2.2rem;
      background: transparent;
      z-index: 9;
    }

    .contentInner {
      display: flex;
      flex-direction: column;
      padding: 6rem 2rem 0;
      min-height: 100vh;
      position: relative;
      z-index: 6;

      .contentContainer {
        flex-grow: 1;
        margin: 0 auto;
        width: 100%;
        max-width: 1440px;
      }
    }
  }
}

.mainLayout.mainLayoutMenuOpened {
  transition: all 0.3s ease;

  .mobileMenuOverlay {
    opacity: 1;
    left: 0;
    transition: left 0s ease, opacity 0.15s ease;
  }

  .mainLayoutSidebar {
    left: 0;
    transition: all 0.3s ease;
  }
}

//body[data-theme='dark'] {
//  .mainLayout {
//    background-color: $dark-body-background;
//
//    .mainLayoutSidebar {
//      background-color: $dark-card-background;
//    }
//
//    .mainLayoutContent {
//      .headerOverlay {
//        height: 82px;
//        background: $dark-body-background;
//      }
//    }
//  }
//}

@media screen and (max-width: 1100px) {
  .mainLayout {
    transition: all 0.3s ease;

    .mobileMenuOverlay {
      display: block;
    }

    .mainLayoutSidebar {
      left: -260px;
      transition: all 0.3s ease;
    }

    .mainLayoutContent {
      padding-left: 0;

      .headerOverlay {
        height: 75px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .mainLayout {
    .mainLayoutContent {
      .contentInner {
        padding-top: 7.5rem;
      }
    }
  }
}
