@import './src/scss/mixins';

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab:hover {
  color: $primary;
}

.ant-tabs-ink-bar {
  background: $primary;
}

.tr-tabs.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding-top: 0;
  font-size: 18px;
}
