@import '../mixins';

.fix-antd-select .ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: 0;
}

.global-filters {
  .ant-select-multiple .ant-select-selection-item-remove > .anticon {
    vertical-align: -0.02em;
  }
}

.ant-select-multiple {
  .ant-select-selector {
    border-color: var(--tr-border-color-gray-1);
    border-radius: $form-border-radius;
  }
}

.ant-select-multiple.ant-select-focused .ant-select-selector {
  border-color: var(--tr-primary);
}

.ant-select,
.ant-select-multiple {
  .ant-select-selector {
    box-shadow: none !important;
  }

  &:hover {
    border-color: var(--tr-primary);
    box-shadow: none !important;

    .ant-select-selector {
      border-color: var(--tr-primary);
      box-shadow: none !important;
    }
  }
}
