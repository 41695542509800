.wrapper {
  margin-top: 60px;

  .title {
    margin-bottom: 16px;
    margin-top: 0;
    color: var(--tr-primary-text-color);
    font-size: 28px;
    line-height: 36px;
    font-weight: 600;
  }
}

@media screen and (max-width: 1100px) {
  .wrapper {
    margin-top: 30px;

    .title {
      font-size: 22px;
      line-height: 30px;
    }
  }
}
