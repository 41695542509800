.card {
  background: var(--tr-sidebar-bg);
  margin-bottom: 2rem;
  // box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  background-clip: border-box;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid var(--tr-border-color-gray-1);

  .title {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1;
    letter-spacing: 0.1px;
    font-size: 18px;
    font-weight: 500;
    color: var(--tr-primary-text-color);
  }

  .cardHeader {
    padding: 22px 22px 0;
  }

  .cardBody {
    padding: 22px;

    .bodyTitle {
      @extend .title;
      margin-bottom: 14px;
    }
  }
}

.card.cardHighlight {
  background: linear-gradient(
    112.14deg,
    var(--primary-light) 0%,
    var(--secondary-hover) 100%
  );
}

@media screen and (max-width: 1024px) {
  .card {
    .cardHeader {
      padding: 16px 16px 0;
    }

    .cardBody {
      padding: 16px;
    }
  }
}

body[data-theme='dark'] {
  .card {
    box-shadow: none;
  }
}
