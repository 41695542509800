@import '../mixins';

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  color: var(--tr-danger);
}

.ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: var(--tr-success);
}

.ant-form-item-label > label {
  font-size: 1.14rem;
  color: var(--tr-muted-text-color);
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-input,
.ant-input-number {
  border-color: var(--tr-border-color-gray-1);
  border-radius: $form-border-radius;
}

.ant-input-affix-wrapper {
  border-color: var(--tr-border-color-gray-1);
  border-radius: $form-border-radius;
}

.ant-select-focused.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-input:focus,
.ant-input-focused,
.ant-input:hover,
.ant-input-number:focus,
.ant-input-number-focused,
.ant-input-number:hover {
  border-color: var(--tr-primary);
  box-shadow: none;
}

.ant-input:focus,
.ant-input-number:focus,
.ant-input-number-focused,
.ant-select-focused {
  box-shadow: $form-shadow;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--tr-primary);
  color: $white;
}

.stock-search {
  border: 0 !important;
  box-shadow: none !important;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  color: var(--tr-primary-text-color);
  border-radius: 0;

  .ant-input-prefix {
    font-size: 20px;
    color: var(--tr-primary-text-color);
  }

  input {
    border: 0 !important;
    box-shadow: none !important;
    padding: 0;
    border-radius: 0;

    &::placeholder {
      color: var(--tr-muted-text-color);
    }
  }

  .ant-input-suffix {
    padding-right: 20px;
    color: var(--tr-primary-text-color);
  }
}
