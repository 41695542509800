.generalPerformance {
  .controls {
    margin-bottom: 24px;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      li {
        a {
          display: block;
          font-size: 12px;
          line-height: 16px;
          font-weight: 500;
          color: var(--tr-primary-text-color);
          border: 1px solid var(--tr-border-color-gray-1);
          border-right: 0;
          padding: 6px 8px;
          transition: all 0.3s ease-in-out;
        }

        a[data-active='yes'] {
          background: var(--tr-sidebar-menu-highlight);
          color: var(--tr-primary);
        }

        &:first-child {
          a {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }
        }

        &:last-child {
          a {
            border-right: 1px solid var(--tr-border-color-gray-1);
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }
        }
      }
    }
  }
}
