@import '../../mixins';

.ant-popover-message,
.ant-popover-inner-content,
.ant-popover {
  color: var(--tr-muted-text-color);
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  border-top-color: var(--tr-sidebar-menu-highlight);
  border-left-color: var(--tr-sidebar-menu-highlight);
}

.ant-popover-inner {
  background-color: var(--tr-sidebar-menu-highlight);
  box-shadow: none;

  .ant-btn,
  .btn {
    color: var(--tr-muted-text-color);
    background: var(--tr-sidebar-menu-highlight);
    border-color: var(--tr-sidebar-menu-highlight);
  }

  .ant-btn-dangerous {
    color: var(--tr-danger);
  }
}
