@import './../mixins';

html,
body {
  padding: 0 !important;
  margin: 0 !important;
  font-family: $base-font-family !important;
}

html {
  font-size: $base-font-size !important;
  letter-spacing: 0.01rem;
}

body {
  color: $black !important;
}

body[data-theme='dark'] {
  color: $white !important;
}

html,
body,
#root {
  min-height: 100%;
}

.fullwidth {
  width: 100%;
}

.border-0 {
  border: 0;
}

.pos-relative {
  position: relative;
}

.ps__rail-x,
.ps__rail-y {
  z-index: 999999;
}

.auth-layout-content {
  .app-animation {
    width: 100%;
  }
}

.match-height {
  > [class*='col'] {
    display: flex;
    flex-flow: column;

    > .match-card-height {
      flex: 1 1 auto;
    }
  }
}

@media (min-width: 1440px) {
  .col-xxl-2 {
    width: 16.666666%;
    flex: 0 0 16.666666%;
    max-width: 16.666666%;
  }

  .col-xxl-3 {
    width: 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-9 {
    width: 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
}

@media screen and (max-width: 1100px) {
  html {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 570px) {
  html {
    font-size: 10px !important;
  }
}
