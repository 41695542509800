.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $text-muted-dark;

  small {
    padding-left: 0.5rem;
    color: $text-muted-dark;
  }
}

.type__wrapper {
  p,
  ul li,
  ol li,
  table tr td,
  th {
    color: $text-muted-dark;
  }
}

p,
ul li,
ol li,
table tr td,
th {
  color: $text-muted-dark;
}
