@import '../mixins';

.ant-modal-content {
  overflow: hidden;
  background: $dark-card-background;
}

.ant-modal-header {
  background: $dark-card-background;
}

.ant-modal-close {
  color: $text-muted-dark;
}

.ant-modal-header {
  color: $text-muted-dark;
  border-color: $text-muted-dark;

  .ant-modal-title {
    color: $text-muted-dark;
  }
}
