.ant-page-header {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.ant-page-header-heading-title {
  font-weight: 700;
}

.ant-page-header-heading-sub-title {
  font-weight: 500;
  color: var(--tr-muted-text-color);
}

@media (min-width: 1200px) {
  .ant-page-header-heading-title {
    font-size: 26px;
    line-height: 36px;
  }
}
