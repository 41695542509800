@import './src/scss/mixins';

.authLayout {
  height: 100vh;
  background: $body-background2;

  .authLayoutInner {
    height: 100vh;
    position: relative;
    z-index: 1;

    .authLayoutLogo {
      position: absolute;
      top: 1.5rem;
      left: 1.5rem;
      width: 100%;
      z-index: 5;

      span {
        font-size: 2.4rem;
        line-height: 1;
        color: $primary;
        font-weight: 700;
      }
    }

    .authLayoutImage {
      height: 100vh;
      align-items: center;
      justify-content: center;

      img {
        display: block;
        width: 100%;
        max-width: 980px;
        height: auto;
      }
    }

    .authLayoutContent {
      padding: 56px;
      padding-right: 71px;
      height: 100vh;
      background: #fff;
    }
  }
}

@media (min-width: 1175px) and (max-width: 1600px) {
  .authLayout {
    .authLayoutInner {
      .authLayoutContent {
        padding: 36px;
        padding-right: 51px;
      }
    }
  }
}

body[data-theme='dark'] {
  .authLayout {
    background: $dark-card-background;
  }

  .authLayout .authLayoutInner .authLayoutContent {
    background: $dark-card-background;
  }
}
