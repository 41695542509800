@import './../mixins';

.simple-pagination {
  .simple-pagination__wrapper {
    .pagination-item {
      span {
        border: 1px solid $dark-form-border;
        color: #fff;
        background-color: $dark-body-background;

        &:hover {
          background: $primary;
          color: $white;
        }
      }
    }

    .pagination-text,
    .pagination-item__disabled {
      span {
        color: $text-muted-dark !important;
      }
    }

    .pagination-item__disabled span {
      color: $dark-form-border !important;
    }
  }
}
