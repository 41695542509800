@import '../../mixins';

label {
  color: var(--tr-muted-text-color);
}

.ant-input-affix-wrapper {
  background: var(--tr-layout-bg);
}

.ant-input-number-handler-wrap {
  background: var(--tr-layout-bg);
}

.ant-input-number-handler {
  color: #fff;
  border-left: 0;

  svg {
    path {
      fill: white;
    }
  }
}

.ant-input-number-handler-down {
  border-top: 0;
}

.ant-picker-clear,
.ant-picker,
.ant-input,
.ant-input-number-input,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background: var(--tr-layout-bg);
  color: var(--tr-muted-text-color);
  border-color: var(--tr-border-color-gray-1);
}

.ant-select-dropdown,
.ant-picker-panel-container {
  background: var(--tr-layout-bg);
  color: var(--tr-muted-text-color);
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background: var(--tr-border-color-gray-1);
  color: var(--tr-muted-text-color);
}

.ant-select-item-option-content,
.ant-picker-header button,
.ant-picker-content th,
.ant-picker-content td,
.ant-picker-header-view,
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner {
  color: var(--tr-muted-text-color);
}

.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: $primary;
}

.ant-picker-input > input {
  color: var(--tr-muted-text-color);
}

.ant-picker-suffix,
.ant-form-item-label > label,
.ant-select-arrow {
  color: var(--tr-muted-text-color);
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-input,
.ant-input-number {
  border-color: var(--tr-border-color-gray-1);
}

.ant-input:focus,
.ant-input-number:focus,
.ant-input-number-focused,
.ant-select-focused {
  box-shadow: $default-card-shadow__dark;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: $text-muted-dark;
}

.ant-picker-panel,
.ant-picker-header,
.ant-picker-datetime-panel .ant-picker-time-panel,
.ant-picker-time-panel-column:not(:first-child),
.ant-picker-panel .ant-picker-footer {
  border-color: $text-muted-dark;
}

.ant-picker-panel .ant-picker-footer {
  border-bottom: 0;
}

.ant-select-dropdown,
body[data-theme='dark'] .ant-picker-panel-container {
  box-shadow: $default-card-shadow__dark;
}

.ant-select-multiple .ant-select-selector {
  background-color: $dark-card-background;
  border: 1px solid $text-muted-dark;
}

.ant-checkbox-inner {
  background-color: $dark-form-border;
  border: 1px solid $dark-form-border;
}

.single-trade__card
  .single-trade__card-content
  .card-content__subgroup
  .card-content__subheader
  span {
  background: $dark-card-background;
  color: $text-muted-dark;
}

.single-trade__card
  .single-trade__card-content
  .card-content__subgroup
  .single-trade__subgroup-table
  tbody
  tr
  td:first-child,
.single-trade__card
  .single-trade__card-content
  .card-content__subgroup
  .single-trade__subgroup-table
  tbody
  tr
  td:last-child {
  color: $text-muted-dark;
}

.single-trade__card
  .single-trade__card-notes
  .trade-notes
  .trade-notes__list
  .trade-notes__item
  .trade-notes__note-date,
.trade-notes__form .ql-editor,
.ql-editor.ql-blank::before {
  color: $text-muted-dark;
}

.single-trade__card
  .single-trade__card-notes
  .trade-notes
  .trade-notes__list
  .trade-notes__item
  .trade-notes__note
  p,
.single-trade__card
  .single-trade__card-notes
  .trade-notes
  .trade-notes__list
  .trade-notes__item
  .trade-notes__note
  ul
  li,
.single-trade__card
  .single-trade__card-notes
  .trade-notes
  .trade-notes__list
  .trade-notes__item
  .trade-notes__note
  ol
  li {
  color: $text-muted-dark;
}
