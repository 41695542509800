.marketNewsHeader {
  background: linear-gradient(
    112.14deg,
    var(--primary-light) 0%,
    var(--secondary-hover) 100%
  );
  border-radius: 12px;
  overflow: hidden;
  padding: 36px 24px;
  margin-bottom: 24px;

  .wrapper {
    .content {
      text-align: center;
      margin-bottom: 24px;

      h1 {
        margin-top: 0;
        margin-bottom: 12px;
        font-size: 36px;
        line-height: 40px;
        color: var(--tr-primary-text-color);
      }

      p {
        margin-bottom: 0;
        font-size: 18px;
        line-height: 20px;
        font-weight: 500;
        color: var(--tr-muted-text-color);
      }
    }

    .search {
      display: block;
      width: 100%;
      max-width: 760px;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 540px) {
  .marketNewsHeader {
    .wrapper {
      .content {
        h1 {
          font-size: 24px;
          line-height: 32px;
        }

        p {
          font-size: 16px;
          line-height: 18px;
        }
      }
    }
  }
}
