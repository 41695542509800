.tradeChart {
  border: 4px solid var(--tr-layout-bg);
  border-radius: 20px;
  overflow: hidden;
}

@media screen and (max-width: 40px) {
  .tradeChart {
    border: 4px solid var(--tr-layout-bg);
  }
}
