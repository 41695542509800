.NewsList {
  margin-top: 88px;
  margin-bottom: 60px;
  padding: 60px 0 88px;

  &__TitleWrapper {
    width: 100%;
    max-width: 680px;
    display: block;
    margin: 0 auto;

    .Title {
      margin-top: 0;
      margin-bottom: 8px;
      font-weight: 800;
      font-size: 28px;
      line-height: 40px;
      color: var(--tr-primary-text-color);
      text-align: center;
    }

    p,
    a {
      margin-bottom: 8px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: var(--tr-muted-text-color);
    }

    a {
      display: block;
      color: var(--tr-primary);
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      margin: 0 auto 24px;

      svg {
        margin-top: -2px;
        margin-left: 4px;

        path {
          fill: var(--tr-primary);
        }
      }
    }
  }

  .News {
    mix-blend-mode: normal;
    border-radius: 6px;

    &__Content {
      position: relative;
      padding: 0 20px;

      .Arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 99;
        left: -20px;
        cursor: pointer;
      }

      .ArrowRight {
        @extend .Arrow;
        left: 100%;
      }

      .Item {
        padding: 28px 24px;
        background: var(--tr-sidebar-bg);
        border-radius: 15px;

        .Image {
          position: relative;
          width: 100%;
          margin-bottom: 18px;

          &__Wrapper {
            display: block;
            position: relative;
            width: 100%;
            height: 240px;
            overflow: hidden;
            border-radius: 8px;

            img {
              position: absolute;
              top: 50% !important;
              left: 50% !important;
              transform: translate(-50%, -50%) !important;
              height: 300px;
              width: auto;
            }
          }
        }

        &__Posted {
          margin-bottom: 10px;
          font-weight: 700;
          font-size: 12px;
          line-height: 18px;
          color: var(--tr-muted-text-color);

          span {
            color: var(--tr-primary);
          }
        }

        &__Title {
          margin-top: 0;
          margin-bottom: 8px;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          height: 48px;
          overflow: hidden;

          a {
            color: var(--tr-primary-text-color);
            transition: all 0.3s ease-in-out;

            &:hover {
              color: var(--tr-primary);
              transition: all 0.3s ease-in-out;
            }
          }
        }

        &__Text {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: var(--tr-muted-text-color);
          height: 64px;
          overflow: hidden;
          margin-bottom: 10px;
        }

        &__Info {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &__Symbol,
          &__Date {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
          }

          &__Symbol {
            margin-right: 6px;
            color: var(--tr-primary);
          }

          &__Date {
            color: var(--tr-primary-text-color);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 570px) {
  .NewsList {
    &__TitleWrapper {
      .Title {
        font-size: 24px;
        line-height: 32px;
      }

      p,
      a {
        font-size: 14px;
        line-height: 20px;
      }

      a {
        font-size: 14px;
      }
    }

    .News {
      &__Content {
        padding: 0;

        .Arrow,
        .ArrowRight {
          display: none;
        }

        .Item {
          padding: 28px 24px;
          background: var(--tr-sidebar-bg);
          border-radius: 15px;

          .Image {
            &__Wrapper {
              height: 140px;

              img {
                height: 200px;
              }
            }
          }
        }
      }
    }
  }
}
