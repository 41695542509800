.totalPerformanceStats {
  height: 100%;
  padding: 22px;
  text-align: center;

  .chartSelectWrapper {
    margin-bottom: 22px;

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    select::-ms-expand {
      display: none;
    }

    select {
      width: 100%;
      max-width: 90px;
      outline: none;
      box-shadow: none;
      border: 1px solid var(--primary);
      border-radius: 6px;
      color: var(--primary);
      padding: 8px 14px;
      font-size: 12px;
      line-height: 12px;
      font-weight: 500;
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.00005 3.879L8.71255 0.166504L9.77305 1.227L5.00005 6L0.227051 1.227L1.28755 0.166504L5.00005 3.879Z' fill='%23007BE8'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: calc(100% - 7px) center;
    }
  }

  .primaryStat {
    margin-top: 0;
    margin-bottom: 14px;
    line-height: 30px;
    font-size: 24px;
    color: var(--tr-primary-text-color);
    letter-spacing: 0.1px;
    font-weight: 600;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      font-size: 13px;
      line-height: 16px;
      letter-spacing: 0.1px;
      color: var(--tr-primary-text-color);
      font-weight: 400;
      margin-bottom: 8px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      strong {
        font-weight: 600;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .link {
    margin-top: 22px;

    a {
      display: inline-block;
      padding: 12px 22px;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.1px;
      background-color: var(--tr-primary);
      border-radius: 6px;
      color: #ffffff;
      box-shadow: 0 8px 25px -8px transparent;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s,
        border 0s;

      &:hover {
        background-color: var(--tr-primaryDark);
        box-shadow: 0 8px 25px -8px var(--tr-primaryDark);
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
          border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background 0s,
          border 0s;
      }
    }
  }
}

body[data-theme='dark'] {
  .totalPerformanceStats {
    .chartSelectWrapper {
      select {
        background-color: var(--tr-sidebar-bg);
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .totalPerformanceStats {
    padding-bottom: 0;

    .primaryStat {
      line-height: 26px;
      font-size: 18px;
    }

    .link {
      display: none;
    }
  }
}
